<template>
  <v-row no-gutters justify="center">
    <v-col cols="9">
        <table-operations/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    "table-operations": () => import('../site/modules/finance/project-operations/table-operations'),
  }
}
</script>

<style scoped>

</style>